declare global {
  interface Window {
    _paq: any;
  }
}

interface MatomoEvent {
  category: TrackingEventCategory;
  action: string;
  name: string;
}

enum TrackingEventCategory {
  Consult = 'CONSULTATION_DE_DONNEES',
  Update = 'MISE_A_JOUR_DE_DONNEES',
  Upload = 'IMPORT',
  Download = 'TELECHARGEMENT',
  Helper = 'AIDE_A_LA_SAISIE',
  Analysis = 'ANALYSE',
}

export const MATOMO_EVENTS = {
  consultSalesforceProfiles: {
    category: TrackingEventCategory.Consult,
    action: 'Consultation SF',
    name: 'Consultation SF DTconso',
  },
  updateEnedisProfiles: {
    category: TrackingEventCategory.Update,
    action: 'MAJ Enedis',
    name: 'MAJ Enedis DTconso',
  },
  downloadCsvTechnicalData: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement Dtconso',
    name: 'Téléchargement CSV Données techniques',
  },
  downloadCsvDetailledMeasurement: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement Dtconso',
    name: 'Téléchargement CSV Mesures détaillées',
  },
  consultContractProfiles: {
    category: TrackingEventCategory.Consult,
    action: 'Consultation SF',
    name: 'Consultation SF Saisie de contrat',
  },
  createContracts: {
    category: TrackingEventCategory.Helper,
    action: 'Création contrat',
    name: 'Création contrat dans salesforce',
  },
  measurementsavailability: {
    category: TrackingEventCategory.Consult,
    action: 'Consultation BDD CDP',
    name: 'Disponibilité de courbe de charge',
  },
  measurementsAnalysis: {
    category: TrackingEventCategory.Analysis,
    action: 'Analyse agrégée',
    name: "Calcul d'une analyse agrégée",
  },
  downloadMeasurementTemplate: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement CDP',
    name: 'Téléchargement modele CDP',
  },
  updateMeasurements: {
    category: TrackingEventCategory.Update,
    action: 'MAJ Enedis CDP',
    name: 'Mise à jour de la courbe avec Enedis',
  },
  importMeasurements: {
    category: TrackingEventCategory.Upload,
    action: 'Import CDP',
    name: "Import d'une courbe de charge",
  },
  downloadMeasurements: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement CDP',
    name: 'Téléchargement CSV Courbe de charge',
  },
  consultTransports: {
    category: TrackingEventCategory.Consult,
    action: 'Consultation acheminement',
    name: 'Consultation BDD acheminement',
  },
  computeTransports: {
    category: TrackingEventCategory.Analysis,
    action: 'Optimisation TURPE',
    name: "Calcul d'une optimisation TURPE",
  },
  refreshCustomTransports: {
    category: TrackingEventCategory.Analysis,
    action: 'Optimisation TURPE',
    name: 'Calcul des acheminements sur mesure',
  },
  downloadCsvTransports: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement TURPE',
    name: 'Téléchargement CSV acheminement',
  },
  computeCustomTransport: {
    category: TrackingEventCategory.Analysis,
    action: 'Optimisation TURPE',
    name: "Calcul d'un acheminement sur mesure",
  },
  downloadMultiDetailledSynthesis: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement TURPE',
    name: 'Téléchargement TURPE pdf multisite détaillé',
  },
  downloadMultiSummarySynthesis: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement TURPE',
    name: 'Téléchargement TURPE pdf multisite résumé',
  },
  downloadMonoSynthesis: {
    category: TrackingEventCategory.Download,
    action: 'Téléchargement TURPE',
    name: 'Téléchargement TURPE pdf monosite',
  },
  importStockPrices: {
    category: TrackingEventCategory.Upload,
    action: 'Import PFC/SPOT',
    name: "Import d'une courbe de prix",
  },
} as const;

export class MatomoService {
  public static trackEvent(event: MatomoEvent, value?: number) {
    window._paq.push([
      'trackEvent',
      event.category,
      event.action,
      event.name,
      value,
    ]);
  }

  public static setUserId(displayName: string) {
    window._paq.push(['setUserId', displayName]);
  }

  public static trackPageView(customTitle: string) {
    window._paq.push(['trackPageView', customTitle]);
  }
}
