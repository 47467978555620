import {
  Commodity,
  ConsumptionProfile,
  EndpointCategory,
  EndpointCommunicationCapability,
  EndpointConnection,
  NumberByTemporalClass,
  TransportCode,
  TransportNetwork,
} from '@Collectif-Energie/collective-lib';
import { Moment } from 'moment';
import 'reflect-metadata';
import {
  RegulatedFta,
  TypeCompteur,
  TypeRaccordement,
  VersionUtilisation,
} from '../../commons/enums';
import { Fta } from '../../commons/types';
import { NewProfilEnergetique } from '../../delivery-points/models/delivery-point.interface';
import { EnedisError } from '../../enedis/models/enedis.interface';

export interface SalesforceProfileRequest {
  prms: string[];
}

export interface SalesforceAccountRequest {
  currentSearch: string;
}

export interface UpdateProfilEnergetiqueRequest {
  username: string;
  prm: string;
  autorisationClient: boolean;
}

export interface UpdateProfileWithEnedisResult {
  technicalDataResult?: EnedisError;
  consommationResult?: EnedisError;
  salesforceResult?: SalesforceOperationResult;
  profile: NewProfilEnergetique;
}

export interface SalesforceOperationResult {
  success: boolean;
  message: string;
}

export interface SalesforceUser {
  displayName: string;
  id: string;
}

export interface IProfileParameters {
  prm: string;
}

export interface SalesforceAccount {
  accountId: string;
  name: string;
  parentId: string;
  naf: string;
  siret: string;
  billingAddress: SalesforceBillingAddress;
  prochaineEcheanceElec: string;
  partnerAffiliationId: string;
  ownerName: string;
  enedisAuthorization: SalesforceEnedisAuthorization;
}

export interface SalesforceEnedisAuthorization {
  isAuthorized: boolean;
  authorizedUntil: Moment;
}

export interface SalesforceBillingAddress {
  city: string;
  country: string;
  countryCode: string;
  postalCode: string;
  state: string;
  stateCode: string;
  street: string;
}

export interface SalesforceProfilEnergetiqueAddress {
  street: string;
  postalCode: string;
  city: string;
}

export enum SalesforceProfilEnergetiqueStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface SalesforceProfilEnergetique {
  salesforceId: string;
  prm: string;
  address: SalesforceProfilEnergetiqueAddress;
  contractualState: string;
  operator: TransportNetwork;
  transportCode: TransportCode | RegulatedFta;
  endpointCategory: EndpointCategory | TypeCompteur;
  consumptionProfile: ConsumptionProfile;
  endpointConnection: EndpointConnection;
  endpointCommunicationCapability: EndpointCommunicationCapability;
  totalConsumption: number;
  connectionMaxPower: number;
  endpointTimeProgramming: string;
  supplierPriceOption: string;
  account: SalesforceAccount;
  consumptionStartDate: Date;
  consumptionEndDate: Date;
  consumptionDuration: number;
  enedisLastCallDate: Date;
  powers: NumberByTemporalClass;
  consumptions: NumberByTemporalClass;
}

export interface SalesforceProfilEnergetiqueUpdate {
  prm: string;
  commodity: Commodity;
  accountId: string;
  address: SalesforceProfilEnergetiqueAddress;
  consumptionProfile: VersionUtilisation;
  endpointCategory: TypeCompteur;
  endpointConnection: TypeRaccordement;
  salesforceUserId: string;
  contractualState: string;
  operator: TransportNetwork;
  transportCode: Fta;
  endpointCommunicationCapability: EndpointCommunicationCapability;
  connectionMaxPower: number;
  endpointTimeProgramming: string;
  supplierPriceOption: string;
  consumptionStartDate: string;
  consumptionEndDate: string;
  enedisLastCallDate: string;
  powers: any;
  consumptions: any;
}
